import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import useShallowSelector from '../../components/hooks/useShallowSelector'
import { clearFrontPage, fetchFrontPage, fetchPreview, fetchSegmentedFrontPage } from './actions'
import { useLoginSession } from '../../components/Auth/AuthContext'

const frontPageRootSelector = state => state.frontPage

const frontPageIsLoadingSelector = createSelector([frontPageRootSelector], ({ loading }) => loading)

export const useFrontPageIsLoading = () => {
  const isLoading = useSelector(frontPageIsLoadingSelector)
  return isLoading
}

const hasErrorSelector = createSelector([frontPageRootSelector], ({ error }) => !!error)

export const useFrontPageHasError = () => {
  const hasError = useSelector(hasErrorSelector)
  return hasError
}

const sectionsSelector = createSelector([frontPageRootSelector], ({ sections }) => sections)

export const useSections = () => {
  const sections = useShallowSelector(sectionsSelector)
  return sections
}

const pageIdSelector = createSelector([frontPageRootSelector], ({ id }) => id)

export const usePageId = () => {
  const pageId = useShallowSelector(pageIdSelector)
  return pageId
}

const recommendationIdSelector = createSelector([frontPageRootSelector], ({ recommendationId }) => recommendationId)

export const useRecommendationId = () => {
  const recommendationId = useShallowSelector(recommendationIdSelector)
  return recommendationId
}

const hasSectionsSelector = createSelector([frontPageRootSelector], ({ sections }) => sections)

export const useHasSections = () => {
  const sections = useShallowSelector(hasSectionsSelector)
  return Array.isArray(sections) && sections.length > 0
}

const hasSegmentSelector = createSelector([frontPageRootSelector], ({ segment }) => !!segment)

export const useHasSegment = () => {
  const hasSegment = useSelector(hasSegmentSelector)
  return hasSegment
}

export const useFrontpageData = () => {
  const dispatch = useDispatch()
  const isLoading = useFrontPageIsLoading()
  const hasError = useFrontPageHasError()
  const hasSections = useHasSections()
  const abortsRef = useRef([])
  const hasSegment = useHasSegment()
  const { isLoggedIn, isExpired } = useLoginSession()

  useEffect(() => {
    if (isLoggedIn && !isExpired && !hasSegment && !isLoading && !hasError) {
      const abortController = new AbortController()
      dispatch(fetchSegmentedFrontPage(abortController))
      abortsRef.current.push(() => abortController.abort())
    }
  }, [dispatch, hasError, hasSegment, isExpired, isLoading, isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn && !hasSections && !isLoading && !hasError) {
      const { abort } = dispatch(fetchFrontPage())
      abortsRef.current.push(() => abort())
    }
  }, [dispatch, hasError, hasSections, isLoading, isLoggedIn])

  useEffect(() => {
    const aborts = abortsRef.current
    return () => {
      dispatch(clearFrontPage())
      aborts.forEach(func => func())
    }
  }, [dispatch])

  const retValue = useMemo(() => {
    return { isLoading }
  }, [isLoading])

  return retValue
}

export const usePreviewpageData = pageId => {
  const dispatch = useDispatch()
  const isLoading = useFrontPageIsLoading()
  const hasError = useFrontPageHasError()
  const hasSections = useHasSections()
  const abortsRef = useRef([])

  useEffect(() => {
    if (!!pageId && !hasSections && !isLoading && !hasError) {
      const { abort } = dispatch(fetchPreview({ pageId }))
      abortsRef.current.push(() => abort())
    }
  }, [dispatch, hasError, hasSections, isLoading, pageId])

  useEffect(() => {
    const aborts = abortsRef.current
    return () => {
      dispatch(clearFrontPage())
      aborts.forEach(func => func())
    }
  }, [dispatch])

  const retValue = useMemo(() => {
    return { isLoading }
  }, [isLoading])

  return retValue
}
const sectionIdSelector = (_, sectionId) => sectionId

const makeSectionIndexSelector = () =>
  createSelector([sectionsSelector, sectionIdSelector], (sections, sectionId) => {
    if (Array.isArray(sections)) {
      return sections.findIndex(({ id }) => id === sectionId)
    }
    return -1
  })

export const useSectionIndex = sectionId => {
  const selectSectionIndex = useMemo(makeSectionIndexSelector, [])
  const sectionIndex = useSelector(state => selectSectionIndex(state, sectionId))
  return sectionIndex
}

export const useSection = sectionId => {
  const sections = useSections()
  const sectionIndex = useSectionIndex(sectionId)

  return Array.isArray(sections) && sections[sectionIndex]
}
