import React from 'react'
import ContentWidth from '../../RadioUI/ContentWidth'
import LiveChannels from '../../components/LiveChannels/LiveChannels'
import MyContentSection from './MyContentSection'
import Scroller from '../../RadioUI/Scroller'
import SectionWrapper from './SectionWrapper'
import HeroPlug from '../../RadioUI/Plugs/HeroPlug'
import SquareLogoPlug from '../../RadioUI/Plugs/SquareLogoPlug'
import SquarePlug from '../../RadioUI/Plugs/SquarePlug'
import EditorialPlug from '../../RadioUI/Plugs/EditorialPlug'
import SimplePlug from '../../RadioUI/Plugs/SimplePlug'
import LandscapePlug from '../../RadioUI/Plugs/LandscapePlug'
import LandscapeLogoPlug from '../../RadioUI/Plugs/LandscapeLogoPlug'
import MultiHeroSection from '../../RadioUI/Plugs/MultiHero'
import createUrl from './helpers/createUrl'
import {
  EDITORIAL,
  HERO,
  LANDSCAPE,
  LANDSCAPE_LOGO,
  MULTI_HERO,
  SIMPLE,
  SQUARED,
  SQUARED_LOGO
} from './helpers/displayContracts'
import {
  INCLUDED,
  PLACEHOLDER,
  PLACEHOLDER_LIVE_CHANNELS,
  PLACEHOLDER_MY_CONTENT,
  PLACEHOLDER_PERSONALISED,
  PLACEHOLDER_CROSSDOMAIN
} from './helpers/sectionTypes'
import { CHANNEL, EPISODE, LINK, PAGE, PODCAST_EPISODE, STANDALONE_PROGRAM } from './helpers/plugTypes'
import extractImageUrl from './helpers/extractImageUrl'
import remapIds from './helpers/remapIds'
import { usePageId, useRecommendationId, useSections } from './hooks'
import SectionWrapperSeeAll from './SectionWrapperSeeAll'
import {
  landscapePlugWidthLarge,
  landscapePlugWidthMedium,
  squarePlugWidthLarge,
  squarePlugWidthSmall
} from '../../RadioUI/Plugs/styles/plugWidths'
import { trackSnowplowEvent, spCategory } from '../../clientMonitoring'
import extractProgramTitles from './helpers/exractTitles'
import PlugEpisodeMenu from '../../RadioUI/Plug/PlugEpisodeMenu/PlugEpisodeMenu'
import PlugDirectLabel from '../../RadioUI/Plug/PlugDirectLabel'
import { useRouteMatch } from 'react-router'
import { generateSectionPath } from './helpers/generateSectionPath'
import Categories from './Categories'
import HeadingReadOnly from '../../components/HeadingReaderOnly'
import PersonalisedRecommendationsSection from './PersonalisedSection'
import CrossdomainSection from './CrossdomainSection'
import { useWindowSize } from '../../components/hooks/useWindowSize'

const FrontPage = () => {
  const pageId = usePageId()
  const recommendationId = useRecommendationId()
  const sections = useSections()
  const match = useRouteMatch()
  const { width } = useWindowSize()
  const isMobile = width ? width < 1024 : false

  return (
    <>
      <HeadingReadOnly heading="Forside" />
      {sections.map((section, position) => {
        if (section.type === PLACEHOLDER && section[PLACEHOLDER].type === PLACEHOLDER_LIVE_CHANNELS) {
          return <LiveChannels key={section.id} />
        } else if (section.type === PLACEHOLDER && section[PLACEHOLDER].type === PLACEHOLDER_MY_CONTENT) {
          return (
            <MyContentSection
              key={`MyContentSection${section.id}`}
              pageId={pageId}
              sectionId={section.id}
              sectionTitle="Mitt innhold"
              indexOfSection={position + 1}
            />
          )
        } else if (section.type === PLACEHOLDER && section[PLACEHOLDER].type === PLACEHOLDER_PERSONALISED) {
          return (
            <PersonalisedRecommendationsSection
              key={section.id}
              position={position}
              sectionId={section.id}
              sectionTitle="Basert på din lytting"
              indexOfSection={position + 1}
              type={section[PLACEHOLDER].type}
              toSectionSeeAll={generateSectionPath(match, section.id)}
              pageId={pageId}
            />
          )
        } else if (section.type === PLACEHOLDER && section[PLACEHOLDER].type === PLACEHOLDER_CROSSDOMAIN) {
          return (
            <CrossdomainSection
              key={section.id}
              position={position + 1}
              sectionId={section.id}
              sectionTitle="Oppdag mer"
              indexOfSection={position + 2}
              type={section[PLACEHOLDER].type}
              toSectionSeeAll={generateSectionPath(match, section.id)}
              pageId={pageId}
            />
          )
        } else if (section.type === INCLUDED) {
          if (section[INCLUDED].displayContract === MULTI_HERO) {
            return (
              <MultiHeroSection
                key={section.id}
                displayContract={section.included.displayContract}
                sectionId={section.id}
                sectionTitle={section[INCLUDED].title}
                plugs={section[INCLUDED].plugs}
                isMobile={isMobile}
                pageId={pageId}
                recommendationId={recommendationId}
                indexOfSection={position + 1}
                onDialogOpen={() => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                }}
              />
            )
          } else if (section[INCLUDED].displayContract === HERO) {
            const [plug] = section[INCLUDED].plugs
            const plugIds = remapIds(plug)
            const { episodeId, channelId, seriesId, seasonId } = plugIds
            const { channelTitle, seriesTitle, episodeTitle } = extractProgramTitles(plug)
            const linkUrl = createUrl(plug)

            return (
              <SectionWrapper key={section.id}>
                <ContentWidth>
                  <HeroPlug
                    linkUrl={linkUrl}
                    imageUrl={extractImageUrl(plug, 1920)}
                    tagline={plug.tagline}
                    title={plug.title}
                    type={plug.type}
                    episodeTitle={episodeTitle}
                    seriesTitle={seriesTitle}
                    startTime={plug[plug.type].startTime}
                    accessibilityLabel={plug.accessibilityLabel}
                    sectionId={section.id}
                    sectionTitle={section.included.title}
                    indexOfSection={position + 1}
                    displayContract={section.included.displayContract}
                    indexInSection={1}
                    imageId={plug.image.id}
                    pageId={pageId}
                    recommendationId={recommendationId}
                    episodeId={episodeId}
                    seriesId={seriesId}
                    channelId={channelId}
                    seasonId={seasonId}
                    contentId={plug.analytics.contentId}
                    contentSource={plug.analytics.contentSource}
                    contentType={plug.analytics.contentType}
                    contentTitle={plug.analytics.title}
                  >
                    {plug.type === CHANNEL && (
                      <PlugDirectLabel
                        position="absolute"
                        top="1rem"
                        left="1rem"
                        startTime={plug.channel?.startDateTime}
                      />
                    )}
                    {![PAGE, LINK].includes(plug.type) && (
                      <PlugEpisodeMenu
                        episodeId={episodeId}
                        seriesId={seriesId}
                        episodeTitle={episodeTitle}
                        seriesTitle={seriesTitle}
                        channelTitle={channelTitle}
                        type={plug.type}
                        linkUrl={linkUrl}
                        duration={plug[plug?.type]?.duration}
                        position="absolute"
                        right={{ base: 6, xl: 12 }}
                        bottom={{ base: 6, xl: 12 }}
                        mb="7px"
                        fontSize="13px"
                        variant=""
                        rounded="full"
                        onDialogOpen={() => {
                          trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                        }}
                      />
                    )}
                  </HeroPlug>
                </ContentWidth>
              </SectionWrapper>
            )
          } else if (section[INCLUDED].displayContract === SQUARED_LOGO) {
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={() => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, seriesId } = plugIds
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const linkUrl = createUrl(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i

                    return (
                      <SquareLogoPlug
                        key={plug.id}
                        title={plug.title}
                        linkUrl={linkUrl}
                        imageUrl={extractImageUrl(plug, 600)}
                        type={plug.type}
                        data-test="squaredLogo-plug"
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        w={squarePlugWidthSmall}
                        accessibilityLabel={plug.accessibilityLabel}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        recommendationId={recommendationId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top="2px"
                            right="2px"
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </SquareLogoPlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else if (section[INCLUDED].displayContract === SQUARED) {
            const anyEpisodes = section[INCLUDED].plugs.some(plug =>
              [PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(plug.type)
            )
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={event => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, channelId, seriesId, seasonId } = plugIds
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const linkUrl = createUrl(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i
                    return (
                      <SquarePlug
                        key={plug.id}
                        linkUrl={linkUrl}
                        imageUrl={extractImageUrl(plug, 600)}
                        title={plug.title || ''}
                        type={plug.type}
                        episodeId={episodeId}
                        channelId={channelId}
                        seriesId={seriesId}
                        seasonId={seasonId}
                        accessibilityLabel={plug.accessibilityLabel}
                        startTime={plug[plug.type].startTime}
                        data-test="squared-plug"
                        w={squarePlugWidthLarge}
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        showPlay={anyEpisodes}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top={anyEpisodes ? '' : '2px'}
                            right={anyEpisodes ? 0 : '2px'}
                            bottom={anyEpisodes ? 2 : ''}
                            h={anyEpisodes ? '24px' : '32px'}
                            w={anyEpisodes ? '24px' : '32px'}
                            size={anyEpisodes ? 'sm' : 'md'}
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </SquarePlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else if (section[INCLUDED].displayContract === EDITORIAL) {
            const anyEpisodes = section[INCLUDED].plugs.some(plug =>
              [PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(plug.type)
            )
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={() => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, channelId, seriesId, seasonId } = plugIds
                    const linkUrl = createUrl(plug)
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i
                    return (
                      <EditorialPlug
                        key={plug.id}
                        imageUrl={extractImageUrl(plug, 600)}
                        linkUrl={linkUrl}
                        tagline={plug.tagline}
                        title={plug.title}
                        description={plug.description}
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        w={landscapePlugWidthLarge}
                        type={plug.type}
                        showPlay={anyEpisodes}
                        startTime={plug[plug.type].startTime}
                        accessibilityLabel={plug.accessibilityLabel}
                        episodeId={episodeId}
                        channelId={channelId}
                        seriesId={seriesId}
                        seasonId={seasonId}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top={anyEpisodes ? '' : '2px'}
                            right={anyEpisodes ? 0 : '2px'}
                            bottom={anyEpisodes ? 2 : ''}
                            h={anyEpisodes ? '24px' : '32px'}
                            w={anyEpisodes ? '24px' : '32px'}
                            size={anyEpisodes ? 'sm' : 'md'}
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </EditorialPlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else if (section[INCLUDED].displayContract === SIMPLE) {
            const anyEpisodes = section[INCLUDED].plugs.some(plug =>
              [PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(plug.type)
            )
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={() => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, channelId, seriesId, seasonId } = plugIds
                    const linkUrl = createUrl(plug)
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i
                    return (
                      <SimplePlug
                        key={plug.id}
                        imageUrl={extractImageUrl(plug, 600)}
                        linkUrl={linkUrl}
                        title={plug.title}
                        description={plug.description}
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        w={landscapePlugWidthMedium}
                        type={plug.type}
                        startTime={plug[plug.type].startTime}
                        accessibilityLabel={plug.accessibilityLabel}
                        episodeId={episodeId}
                        channelId={channelId}
                        seriesId={seriesId}
                        seasonId={seasonId}
                        showPlay={anyEpisodes}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top={anyEpisodes ? '' : '2px'}
                            right={anyEpisodes ? 0 : '2px'}
                            bottom={anyEpisodes ? 2 : ''}
                            h={anyEpisodes ? '24px' : '32px'}
                            w={anyEpisodes ? '24px' : '32px'}
                            size={anyEpisodes ? 'sm' : 'md'}
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </SimplePlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else if (section[INCLUDED].displayContract === LANDSCAPE) {
            const anyEpisodes = section[INCLUDED].plugs.some(plug =>
              [PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(plug.type)
            )
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={event => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, channelId, seriesId, seasonId } = plugIds
                    const linkUrl = createUrl(plug)
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i
                    return (
                      <LandscapePlug
                        key={plug.id}
                        imageUrl={extractImageUrl(plug, 600)}
                        linkUrl={linkUrl}
                        title={plug.title}
                        description={plug.description}
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        w={landscapePlugWidthMedium}
                        type={plug.type}
                        startTime={plug[plug.type].startTime}
                        accessibilityLabel={plug.accessibilityLabel}
                        episodeId={episodeId}
                        channelId={channelId}
                        seriesId={seriesId}
                        seasonId={seasonId}
                        showPlay={anyEpisodes}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top={anyEpisodes ? '' : '2px'}
                            right={anyEpisodes ? 0 : '2px'}
                            bottom={anyEpisodes ? 2 : ''}
                            h={anyEpisodes ? '24px' : '32px'}
                            w={anyEpisodes ? '24px' : '32px'}
                            size={anyEpisodes ? 'sm' : 'md'}
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </LandscapePlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else if (section[INCLUDED].displayContract === LANDSCAPE_LOGO) {
            return (
              <SectionWrapperSeeAll
                key={section.id}
                to={generateSectionPath(match, section.id)}
                title={section[INCLUDED].title}
                onClick={event => {
                  trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped', section[INCLUDED].title)
                }}
              >
                <Scroller>
                  {section[INCLUDED].plugs.map((plug, i) => {
                    const plugIds = remapIds(plug)
                    const { episodeId, channelId, seriesId } = plugIds
                    const linkUrl = createUrl(plug)
                    const { episodeTitle, seriesTitle, channelTitle } = extractProgramTitles(plug)
                    const last = section[INCLUDED].plugs.length - 1 === i
                    return (
                      <LandscapeLogoPlug
                        key={plug.id}
                        title={plug.title}
                        imageUrl={extractImageUrl(plug, 600)}
                        linkUrl={linkUrl}
                        description={plug.description}
                        mr={{ base: last ? 0 : 4, md: last ? 0 : 6 }}
                        w={landscapePlugWidthMedium}
                        type={plug.type}
                        startTime={plug[plug.type].startTime}
                        accessibilityLabel={plug.accessibilityLabel}
                        episodeId={episodeId}
                        channelId={channelId}
                        seriesId={seriesId}
                        sectionId={section.id}
                        sectionTitle={section.included.title}
                        indexOfSection={position + 1}
                        displayContract={section.included.displayContract}
                        indexInSection={i + 1}
                        imageId={plug.image.id}
                        pageId={pageId}
                        contentTitle={plug.analytics.title}
                        contentId={plug.analytics.contentId}
                        contentSource={plug.analytics.contentSource}
                        contentType={plug.analytics.contentType}
                      >
                        {plug.type === CHANNEL && (
                          <PlugDirectLabel
                            position="absolute"
                            top="0.5rem"
                            left="0.5rem"
                            startTime={plug.channel?.startDateTime}
                          />
                        )}
                        {![PAGE, LINK].includes(plug.type) && (
                          <PlugEpisodeMenu
                            episodeId={episodeId}
                            seriesId={seriesId}
                            episodeTitle={episodeTitle}
                            seriesTitle={seriesTitle}
                            channelTitle={channelTitle}
                            type={plug.type}
                            linkUrl={linkUrl}
                            position="absolute"
                            top="2px"
                            right="1px"
                            onDialogOpen={() => {
                              trackSnowplowEvent(spCategory.Frontpage, 'ActionMenuButtonTapped')
                            }}
                          />
                        )}
                      </LandscapeLogoPlug>
                    )
                  })}
                </Scroller>
              </SectionWrapperSeeAll>
            )
          } else {
            return null
          }
        }
        return null
      })}
      <Categories />
    </>
  )
}

export default FrontPage
