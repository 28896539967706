import React, { useState } from 'react'
import ErrorMessage from '../../../components/LoadableComponentErrorBoundry/ErrorMessage'
import ContentWidth from '../../../RadioUI/ContentWidth'
import { useMyContentIsLoading, useFetchMyContent } from '../../../components/MyContent/MyContentContext'
import SectionWrapper from '../SectionWrapper'
import { useColors } from '../../../RadioUI/theme/Color'
import { useIsLoggedIn } from '../../../components/Auth/AuthContext'

const MyContentError = props => {
  const [tries, setTries] = useState(0)
  const fetchMyContent = useFetchMyContent()
  const isLoading = useMyContentIsLoading()
  const isLoggedIn = useIsLoggedIn()
  const shouldReload = !isLoggedIn || tries > 2
  const maybeMessageAndButtonText = shouldReload
    ? {}
    : {
        message: 'Beklager. Kunne ikke laste ditt innhold akkurat nå.',
        buttonText: 'Prøv igjen'
      }
  return (
    <SectionWrapper title="Mitt innhold">
      <ContentWidth height="full">
        <ErrorMessage
          onErrorClick={() => {
            if (shouldReload) {
              document.location.reload()
            } else {
              setTries(tries + 1)
              fetchMyContent()
            }
          }}
          {...maybeMessageAndButtonText}
          disabled={isLoading}
          bg={useColors().main.dark}
          height="full"
          rounded="md"
        />
      </ContentWidth>
    </SectionWrapper>
  )
}

MyContentError.propTypes = {}

export default MyContentError
