import React, { useRef } from 'react'
import LiveChannelsWrapper from './LiveChannelsWrapper'
import LiveChannelsList from './LiveChannelsList'
import Scroller from '../../RadioUI/Scroller'

const LiveChannels = ({ ...restProps }) => {
  const scrollRef = useRef()

  return (
    <LiveChannelsWrapper wrapperRef={scrollRef} {...restProps}>
      <Scroller plugWidth={178}>
        <LiveChannelsList />
      </Scroller>
    </LiveChannelsWrapper>
  )
}

LiveChannels.propTypes = {}

export default LiveChannels
