import PropTypes from 'prop-types'
import React from 'react'
import { liveChannels } from '../../../common/liveChannels'
import IconButton from '../../../RadioUI/IconButton'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { useColors } from '../../../RadioUI/theme/Color'
import CoreToggle from '@nrk/core-toggle/jsx'

const districtChannels = liveChannels.filter(channel => channel.isDistrictChannel)

const DistrictDropdown = ({ onDistrictClick, userDistrictChannelId }) => {
  const colors = useColors()

  return (
    <>
      <IconButton
        aria-label="Velg distrikt"
        aria-haspopup="true"
        iconId="nrk-arrow-dropdown"
        className="navigate district-dropdown"
        variant="transparent"
      />
      <CoreToggle
        hidden
        data-popup
        onToggleSelect={event => {
          event.target.hidden = true
        }}
      >
        <PseudoBox
          as="ul"
          w="220px"
          zIndex="1400"
          position={{ base: 'fixed', md: 'absolute' }}
          top="50%"
          left="50%"
          transform="translateX(-50%) translateY(-50%)"
          maxH="100vh"
          overflow="auto"
          background={colors.main.mediumLight10}
        >
          {districtChannels &&
            districtChannels.map(districtChannel => {
              return (
                <PseudoBox
                  as="li"
                  key={districtChannel.id}
                  background={
                    userDistrictChannelId === districtChannel.id ? colors.main.light : colors.main.mediumLight10
                  }
                  color={userDistrictChannelId === districtChannel.id ? colors.main.dark : colors.main.contrastLight}
                  _focus={{ background: colors.main.mediumLight20 }}
                  _hover={{ background: colors.main.mediumLight20 }}
                  _active={{ background: colors.main.light, color: colors.main.dark }}
                  paddingLeft="1rem"
                  py="6px"
                  cursor="pointer"
                  border="none"
                >
                  <button
                    onClick={event => {
                      onDistrictClick(event, districtChannel.id)
                    }}
                    className={userDistrictChannelId === districtChannel.id ? 'bla' : undefined}
                    style={{ fontWeight: 'bold' }}
                  >
                    {districtChannel.title}
                  </button>
                </PseudoBox>
              )
            })}
        </PseudoBox>
      </CoreToggle>
    </>
  )
}

const element = typeof Element === 'undefined' ? function() {} : Element // SSR-workaround. global Element doesn't exist in node

DistrictDropdown.propTypes = {
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(element) }),
  onDistrictClick: PropTypes.func.isRequired,
  userDistrictChannelId: PropTypes.string.isRequired
}

export default DistrictDropdown
