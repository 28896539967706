import React from 'react'
import PropTypes from 'prop-types'
import Scroller from '../../../RadioUI/Scroller'
import { crossdomainPlugWidth } from '../../../RadioUI/Plugs/styles/plugWidths'
import SectionWrapper from '../SectionWrapper'
import Box from '../../../RadioUI/Box'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'
import ContentWidth from '../../../RadioUI/ContentWidth'

const CrossdomainSectionLoading = ({ showContent = false, ...restProps }) => {
  return (
    <SectionWrapper>
      <ContentWidth>
        <LoadingPlaceholder h="40px" display="block" />
      </ContentWidth>
      <Scroller>
        {[...Array(8).keys()].map(key => {
          return (
            <Box flex="0 0 auto" key={key} w={crossdomainPlugWidth} mr="4">
              <LoadingPlaceholder w={crossdomainPlugWidth} h={crossdomainPlugWidth} display="block" />
            </Box>
          )
        })}
      </Scroller>
    </SectionWrapper>
  )
}

CrossdomainSectionLoading.propTypes = {
  showContent: PropTypes.bool
}

export default CrossdomainSectionLoading
