import React from 'react'
import PropTypes from 'prop-types'
import Scroller from '../../../RadioUI/Scroller'
import { squarePlugWidthMedium } from '../../../RadioUI/Plugs/styles/plugWidths'
import SectionWrapper from '../SectionWrapper'
import Box from '../../../RadioUI/Box'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

const PersonalisedRecommendationsLoading = ({ showContent = false, ...restProps }) => {
  return (
    <SectionWrapper>
      <Scroller>
        {[...Array(10).keys()].map(key => {
          return (
            <Box flex="0 0 auto" key={key} w={squarePlugWidthMedium} mr={{ base: 4, md: 6 }}>
              <LoadingPlaceholder w={squarePlugWidthMedium} h={squarePlugWidthMedium} />
            </Box>
          )
        })}
      </Scroller>
    </SectionWrapper>
  )
}

PersonalisedRecommendationsLoading.propTypes = {
  showContent: PropTypes.bool
}

export default PersonalisedRecommendationsLoading
