import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import LiveChannelLogo from '../../LiveChannelLogo'
import { useLivebufferNowChannelTitle, useLivebufferNowChannelEntryTitle } from '../hooks/useLivebufferNow'
import { persistLatestChannels } from '../actions'
import Text from '../../../RadioUI/Text'
import LiveChannelImageAndLogoWrapper from './LiveChannelImageAndLogoWrapper'
import { MEDIA_TYPES } from '../../StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../Queues/UpNext/UpNextContext'

const noop = () => {}

const LiveChannelCard = ({ channelId, onClick = noop, contentContext, favouriteLevel }) => {
  const channelTitle = useLivebufferNowChannelTitle(channelId)
  const liveTitle = useLivebufferNowChannelEntryTitle(channelId)
  const dispatch = useDispatch()
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()
  return (
    <div className="radio-channel-link">
      <button
        className="channel-button"
        onClick={event => {
          setUpNextContentContext(contentContext)
          setUpNextFavoriteLevel(favouriteLevel)
          dispatch(persistLatestChannels(channelId))
          loadAndStartOrPlayFrom({ channelId, mediaType: MEDIA_TYPES.CHANNEL, seekTo: new Date() })
          onClick(event, channelId)
        }}
        aria-label={`Start ${channelTitle}${liveTitle ? `, spilles nå: ${liveTitle}` : ''}`}
      >
        <LiveChannelImageAndLogoWrapper data-test={`liveChannel-${channelTitle}`}>
          <LiveChannelLogo channelId={channelId} />
        </LiveChannelImageAndLogoWrapper>
        <Text size="md" weight="medium" py={2} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {liveTitle || channelTitle}
        </Text>
      </button>
    </div>
  )
}

LiveChannelCard.propTypes = {
  channelId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default LiveChannelCard
