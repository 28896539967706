import { css } from '@emotion/react'
import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../RadioUI/Box'

const styles = css`
  .district-dropdown {
    position: absolute;
    cursor: pointer;
    text-indent: initial;
    height: 24px;
    right: 8px;
    width: 24px;
    padding: 0;
  }

  .heading-title {
    letter-spacing: 1px;
    margin: 0 8px 0 0;
    text-transform: uppercase;
  }

  .channel-current-program-title {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;

    span {
      box-sizing: border-box;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  .heading.box-heading {
    border-bottom: none;
    font-weight: 500;
    margin: 0;
    padding: 0 0 10px 0;
  }

  .channel-selector-bottom {
    margin-top: 15px;
  }

  .radio-channel-link {
    padding-right: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus,
    &:active {
      .channel-button,
      .district-dropdown {
        transform: translate3d(0, -4px, 0);
      }
    }

    .channel-button {
      border: none;
      background: transparent;
      padding: 0px;
      display: block;
      width: 100%;
      min-width: 100%;
      min-height: 100%;
      text-align: left;
      cursor: pointer;
      &:focus,
      &:focus-visible {
        outline: none;
        opacity: 0.7;
      }
    }
  }

  .discover-scroll-button-wrapper {
    width: 182px;
  }
`

const LiveChannelsWrapper = ({ children, wrapperRef, ...restProps }) => {
  return (
    <Box
      ref={wrapperRef}
      className="live-channels-wrapper"
      data-test="channel-container"
      css={styles}
      mb={{ base: 4, md: 8 }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

LiveChannelsWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  wrapperRef: PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.elementType]) })
}

export default LiveChannelsWrapper
