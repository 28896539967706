import React from 'react'
import MyContentLoadingPlug from '../../../RadioUI/Plugs/MyContentPlug/MyContentLoadingPlug'
import Scroller from '../../../RadioUI/Scroller'
import { landscapePlugWidth } from '../../../RadioUI/Plugs/styles/plugWidths'
import SectionWrapper from '../SectionWrapper'

const MyContentLoading = ({ ...restProps }) => {
  return (
    <SectionWrapper title="Mitt innhold" {...restProps}>
      <Scroller>
        <MyContentLoadingPlug mr={{ base: 4, md: 6 }} w={landscapePlugWidth} />
        <MyContentLoadingPlug mr={{ base: 4, md: 6 }} w={landscapePlugWidth} />
        <MyContentLoadingPlug mr={{ base: 4, md: 6 }} w={landscapePlugWidth} />
        <MyContentLoadingPlug mr={{ base: 4, md: 6 }} w={landscapePlugWidth} />
      </Scroller>
    </SectionWrapper>
  )
}

MyContentLoading.propTypes = {}

export default MyContentLoading
