import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import PseudoBox from '../../../RadioUI/PseudoBox'

const LiveChannelImageAndLogoWrapper = forwardRef(({ children, ...restProps }, ref) => {
  return (
    <PseudoBox
      ref={ref}
      className="channel-image-container teeest"
      borderRadius="6px"
      pos="relative"
      pr="2rem"
      pl="2rem"
      display="flex"
      aligenItems="center"
      justifyContent="center"
      height="104px"
      width="100%"
      background="linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);"
      {...restProps}
    >
      {children}
    </PseudoBox>
  )
})

LiveChannelImageAndLogoWrapper.displayName = 'LiveChannelImageAndLogoWrapper'

LiveChannelImageAndLogoWrapper.propTypes = {
  children: PropTypes.node
}

export default LiveChannelImageAndLogoWrapper
