import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useLivebufferNowChannelTitle } from '../hooks/useLivebufferNow'
import { persistLatestChannels } from '../actions'
import Text from '../../../RadioUI/Text'
import AspectRatioBox from '../../../RadioUI/AspectRatioBox'
import Box from '../../../RadioUI/Box'
import LiveChannelImageAndLogoWrapper from './LiveChannelImageAndLogoWrapper'

const noop = () => {}

const ServerSideCard = ({ channelId, onClick = noop }) => {
  const dispatch = useDispatch()
  const channelTitle = useLivebufferNowChannelTitle(channelId)
  return (
    <div className="radio-channel-link">
      <Link
        onClick={event => {
          dispatch(persistLatestChannels(channelId))
          onClick(event, channelId)
        }}
        to={`/direkte/${channelId}`}
        aria-hidden="false"
      >
        <LiveChannelImageAndLogoWrapper data-test={`liveChannel-${channelTitle}`}>
          <span data-test="liveChannelsEPG" className="nrk-sr">
            {channelTitle}
          </span>
          <AspectRatioBox>
            <Box />
          </AspectRatioBox>
        </LiveChannelImageAndLogoWrapper>
        <Text size="md" weight="medium" py={2}>
          {channelTitle}
        </Text>
      </Link>
    </div>
  )
}

ServerSideCard.propTypes = {
  channelId: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default ServerSideCard
