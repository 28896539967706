import React from 'react'
import PropTypes from 'prop-types'
import clientConfig from '../clientConfig'
import Box from '../RadioUI/Box'

const rewrites = {
  national_rap_show: 'p3-national-rap-show',
  radioresepsjonen: 'p13-radioresepsjonen',
  urort: 'p3-urort',
  p3x: 'p3-x',
  alltid_nyheter: 'nyheter',
  radio_super: 'super',
  p1pluss: 'p1+',
  sapmi: 'sami-radio'
}

const LiveChannelLogo = ({ channelId, stacked, ...restProps }) => {
  if (!channelId) return null
  const id = rewrites[channelId] || channelId

  return (
    <Box
      as="img"
      className={`${id}`}
      src={`${clientConfig.DIST_PATH}/images/channel-logos/nrk-logo-nrk-${id}--${
        stacked?.find(s => s === channelId) ? 'stacked-' : ''
      }color-light.svg`}
      alt=""
      aria-hidden="true"
      {...restProps}
    />
  )
}

LiveChannelLogo.propTypes = {
  channelId: PropTypes.string,
  stacked: PropTypes.array
}

export default LiveChannelLogo
