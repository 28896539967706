import componentLoader from '../../../components/HOCs/componentLoader'
import MyContentLoading from './MyContentLoading'
import MyContentError from './MyContentError'

const LazyMyContentSection = componentLoader({
  loader: () => import('./MyContent'),
  LoadingComponent: MyContentLoading,
  ErrorMessageComponent: MyContentError
})

export default LazyMyContentSection
