import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { getTopLevelId } from '../../../common/liveChannels'
import LiveChannelLogo from '../../LiveChannelLogo'
import DistrictDropdown from './DistrictDropdown'
import { useLivebufferNowChannelEntryTitle, useLivebufferNowChannelTitle } from '../hooks/useLivebufferNow'
import { persistLatestChannels } from '../actions'
import Text from '../../../RadioUI/Text'
import LiveChannelImageAndLogoWrapper from './LiveChannelImageAndLogoWrapper'
import { useUserDistrictChannelId } from '../../../pages/Direct/DistrictChannelContext'
import { MEDIA_TYPES } from '../../StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../Queues/UpNext/UpNextContext'

const noop = () => {}

const P1Card = ({ channelId, onClick = noop, onDistrictChange = noop, contentContext, favouriteLevel }) => {
  const dispatch = useDispatch()
  const channelTitle = useLivebufferNowChannelTitle(channelId)
  const liveProgramTitle = useLivebufferNowChannelEntryTitle(channelId)
  const { userDistrictChannelId, setUserDistrictChannelId } = useUserDistrictChannelId()
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const handleDistrictClick = useCallback(
    (event, id) => {
      setUpNextContentContext(contentContext)
      setUpNextFavoriteLevel(favouriteLevel)
      setUserDistrictChannelId(id)
      dispatch(persistLatestChannels(id))
      loadAndStartOrPlayFrom({ channelId, mediaType: MEDIA_TYPES.CHANNEL, seekTo: new Date() })
      onClick(event, id)
    },
    [
      channelId,
      dispatch,
      loadAndStartOrPlayFrom,
      onClick,
      setUserDistrictChannelId,
      setUpNextContentContext,
      contentContext,
      setUpNextFavoriteLevel,
      favouriteLevel
    ]
  )

  const handleDistrictChange = useCallback(
    (event, districtId) => {
      handleDistrictClick(event, districtId)
      onDistrictChange(event, districtId)
    },
    [handleDistrictClick, onDistrictChange]
  )

  return (
    <div className="radio-channel-link">
      <button
        className="channel-button"
        onClick={event => handleDistrictClick(event, userDistrictChannelId)}
        aria-label={`Start ${channelTitle}, spilles nå: ${liveProgramTitle}`}
      >
        <LiveChannelImageAndLogoWrapper data-test={`liveChannel-${channelTitle}`}>
          <LiveChannelLogo channelId={getTopLevelId(channelId)} />
        </LiveChannelImageAndLogoWrapper>
        <Text size="md" weight="medium" py={2}>
          {liveProgramTitle || channelTitle}
        </Text>
      </button>
      <DistrictDropdown userDistrictChannelId={userDistrictChannelId} onDistrictClick={handleDistrictChange} />
    </div>
  )
}

P1Card.propTypes = {
  channelId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDistrictChange: PropTypes.func,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default P1Card
