import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLivebufferNowSorted, useLivebufferNow } from './hooks/useLivebufferNow'
import isDistrictId from '../../common/isDistrictId'
import P1Card from './LiveChannel/P1Card'
import LiveChannelCard from './LiveChannel/LiveChannelCard'
import ServerSideCard from './LiveChannel/ServerSideCard'

const LiveChannelsList = ({ onClick, onDistrictChange }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const channels = useLivebufferNow()
  const sortedChannels = useLivebufferNowSorted()

  if (isBrowser) {
    return (
      <>
        {sortedChannels.map((channel, i) => {
          if (isDistrictId(channel.id)) {
            return (
              <P1Card key={channel.id} channelId={channel.id} onClick={onClick} onDistrictChange={onDistrictChange} />
            )
          }
          return <LiveChannelCard key={channel.id} channelId={channel.id} onClick={onClick} />
        })}
      </>
    )
  }

  return (
    <>
      {channels.map(channel => {
        return <ServerSideCard key={channel.id} channelId={channel.id} onClick={onClick} />
      })}
    </>
  )
}

LiveChannelsList.propTypes = {
  onClick: PropTypes.func,
  onDistrictChange: PropTypes.func
}

export default LiveChannelsList
