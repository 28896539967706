import { liveChannelsNonLocal } from '../../../common/liveChannels'
import { localStorage } from '../../../common/storage'

export const getPersistedLastViewedChannelIds = () => {
  const lastViewed = localStorage.getItem('rad-lastViewed')
  if (lastViewed) {
    return lastViewed?.split?.(',')
  } else {
    // Fallback to hardcoded channels / default sort if user has no persisted channels
    return liveChannelsNonLocal.map(({ id }) => id)
  }
}

export const sortChannels = (channels, lastViewedChannels = getPersistedLastViewedChannelIds()) => {
  const sortedChannels = [...channels].sort((a, b) => {
    const A = a.id.startsWith('p1_') ? 'p1' : a.id
    const B = b.id.startsWith('p1_') ? 'p1' : b.id
    if (lastViewedChannels.indexOf(A) < 0) {
      // Previously unknown channels should be sorted last
      return 1
    }
    return lastViewedChannels.indexOf(A) - lastViewedChannels.indexOf(B)
  })
  return sortedChannels
}
