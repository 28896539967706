import React, { Fragment } from 'react'
import AspectRatioBox from '../../RadioUI/AspectRatioBox'
import Box from '../../RadioUI/Box'
import ContentWidth from '../../RadioUI/ContentWidth'
import LoadingPlaceholder from '../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'
import HeroLoadingPlaceholder from '../../RadioUI/Plugs/HeroPlug/HeroLoadingPlaceholder'
import MultiHeroLoadingPlaceholder from '../../RadioUI/Plugs/MultiHero/MultiHeroLoadingPlaceholder'
import MyContentLoadingPlug from '../../RadioUI/Plugs/MyContentPlug/MyContentLoadingPlug'
import SimpleLoadingPlaceholder from '../../RadioUI/Plugs/SimplePlug/SimpleLoadingPlaceholder'
import SquareLogoLoadingPlaceholder from '../../RadioUI/Plugs/SquareLogoPlug/SquareLogoLoadingPlaceholder'
import { landscapePlugWidth, squarePlugWidth } from '../../RadioUI/Plugs/styles/plugWidths'
import Scroller from '../../RadioUI/Scroller'
import SectionWrapper from './SectionWrapper'

const FrontPageLoading = () => {
  return (
    <>
      <SectionWrapper title=" ">
        <MultiHeroLoadingPlaceholder />
      </SectionWrapper>
      <SectionWrapper title=" ">
        <Scroller>
          {[...Array(4).keys()].map(key => {
            return <MyContentLoadingPlug key={key} mr={{ base: 4, md: 6 }} w={landscapePlugWidth} />
          })}
        </Scroller>
      </SectionWrapper>
      <SectionWrapper title=" ">
        <Scroller>
          {[...Array(10).keys()].map(key => {
            return (
              <Box flex="0 0 auto" key={key} w="182px" mr={{ base: 4, md: 6 }}>
                <AspectRatioBox ratio={16 / 9}>
                  <LoadingPlaceholder w="182px" />
                </AspectRatioBox>
                <Box py="2">
                  <LoadingPlaceholder h="21px" />
                </Box>
              </Box>
            )
          })}
        </Scroller>
      </SectionWrapper>
      {[...Array(2).keys()].map(key => {
        return (
          <Fragment key={key}>
            <SectionWrapper title=" ">
              <ContentWidth>
                <HeroLoadingPlaceholder />
              </ContentWidth>
            </SectionWrapper>
            <SectionWrapper title=" ">
              <Scroller>
                {[...Array(8).keys()].map(key => {
                  return <SquareLogoLoadingPlaceholder key={key} w={squarePlugWidth} mr={{ base: 4, md: 6 }} />
                })}
              </Scroller>
            </SectionWrapper>
            <SectionWrapper title=" ">
              <Scroller>
                {[...Array(4).keys()].map(key => {
                  return <SimpleLoadingPlaceholder key={key} w={landscapePlugWidth} mr={{ base: 4, md: 6 }} />
                })}
              </Scroller>
            </SectionWrapper>
          </Fragment>
        )
      })}
    </>
  )
}

export default FrontPageLoading
