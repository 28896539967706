import { useMemo } from 'react'
import { createSelector } from 'reselect'
import { useSelector, shallowEqual } from 'react-redux'
import { sortChannels } from '../../../pages/Direct/helpers/sortChannels'
import isDistrictId from '../../../common/isDistrictId'
import useShallowSelector from '../../hooks/useShallowSelector'
import { useUserDistrictChannelId } from '../../../pages/Direct/DistrictChannelContext'

const liveBufferSelector = state => state.liveBuffer

export const liveBufferNowSelector = createSelector([liveBufferSelector], liveBuffer => liveBuffer.channels)

export const useLivebufferNow = () => {
  const channels = useSelector(liveBufferNowSelector) || []
  const { userDistrictChannelId: userDistrictChannelIdFromStorage } = useUserDistrictChannelId()
  const userDistricChannelId = isDistrictId(userDistrictChannelIdFromStorage)
    ? userDistrictChannelIdFromStorage
    : 'p1_oslo_akershus'
  return channels?.filter?.(
    ({ id, type }) => (id !== 'p1' && type !== 'districtChannel') || id === userDistricChannelId
  )
}

export const useLivebufferNowWithDefault = () => {
  const channels = useSelector(liveBufferNowSelector) || []
  const userDistricChannelId = 'p1_oslo_akershus'
  return channels?.filter?.(
    ({ id, type }) => (id !== 'p1' && type !== 'districtChannel') || id === userDistricChannelId
  )
}

export const useLivebufferNowDistrictChannels = () => {
  const channels = useSelector(liveBufferNowSelector) || []
  return channels.filter(({ type }) => type === 'districtChannel')
}

export const useLivebufferNowSorted = () => {
  const channels = useLivebufferNow(liveBufferNowSelector)
  return sortChannels(channels)
}

const makeLivebufferChannelSelector = () =>
  createSelector([liveBufferNowSelector, (_, channelId) => channelId], (channels, channelId) => {
    const channel = channels.find(({ id }) => id === channelId)
    return channel
  })

export const useLivebufferNowChannel = channelId => {
  const selectChannel = useMemo(makeLivebufferChannelSelector, [])
  const channel = useShallowSelector(state => selectChannel(state, channelId), shallowEqual)
  return channel
}

export const useLivebufferNowChannelTitle = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  return channel?.title
}

export const useLivebufferNowChannelImage = (channelId, minWidth = 1024) => {
  const channel = useLivebufferNowChannel(channelId)
  const image = channel?.image?.images?.find(({ width }) => {
    return width >= minWidth
  })

  return image?.url
}

export const useChannelImageSmall = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  return channel?.image?.images?.[0]?.url
}

export const useLivebufferNowEntryProgramId = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  return channel?.entry?.programId
}

export const useLivebufferNowChannelEntryTitle = channelId => {
  const channel = useLivebufferNowChannel(channelId)

  return channel?.entry?.title
}

export const useLivebufferNowChannelEntryStartTime = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  const actualStart = channel?.entry?.actualStart
  return actualStart ? new Date(actualStart) : null
}

export const useLivebufferNowChannelEntryEndTime = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  const actualEnd = channel?.entry?.actualEnd
  return actualEnd ? new Date(actualEnd) : null
}

export const useLivebufferNowChannelEntryImage = (channelId, minWidth = 1024) => {
  const channel = useLivebufferNowChannel(channelId)
  const image = channel?.entry?.image?.mainKeyArtImage?.images?.find(({ width }) => {
    return width >= minWidth
  })
  return image?.url
}

export const useLivebufferNowChannelEntryImageSmall = channelId => {
  const channel = useLivebufferNowChannel(channelId)
  return channel?.entry?.image?.mainKeyArtImage?.images?.[0]?.url
}
