import React from 'react'
import { spCategory, trackSnowplowEvent } from '../../clientMonitoring'
import CategoryList from '../Category/CategoryList/CategoryList'
import SectionWrapper from './SectionWrapper'

const Categories = () => {
  return (
    <SectionWrapper pb="32" title="Hva vil du høre?">
      <CategoryList
        data-test="category-list"
        onClick={({ id }) => {
          trackSnowplowEvent(spCategory.Frontpage, 'CategoryTapped', id)
        }}
      />
    </SectionWrapper>
  )
}

export default Categories
